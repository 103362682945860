<template>
  <v-card class="mx-auto my-10" max-width="500">
    <v-card-text>
      <p class="text-h4 text--primary text-center">Bienvenue au SOC</p>
      <p class="text-body-1 text-center">Êtes-vous...</p>
      <v-row class="my-2">
        <router-link
          :to="{
            name: 'signin',
            query: { routeName: 'permanency-appointment-booking' },
          }"
          custom
          v-slot="{ href, navigate }"
        >
          <v-btn color="primary" :href="href" :block="true" @click="navigate">
            Membre de l'UNIL
          </v-btn>
        </router-link>
      </v-row>
      <v-row class="my-2">
        <router-link
          :to="{ name: 'permanency-appointment-booking' }"
          custom
          v-slot="{ href, navigate }"
        >
          <v-btn color="primary" :href="href" :block="true" @click="navigate">
            Externe à l'UNIL
          </v-btn>
        </router-link>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
