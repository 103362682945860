<template>
  <v-card>
    <v-row justify="center" v-if="displayError">
      <v-col cols="12" md="6">
        <v-alert type="error"
          >Une erreur est survenue lors du chargement des plages
          disponibles</v-alert
        >
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" lg="3">
        <p class="text-h5">Choix de la date</p>
        <v-alert
          type="error"
          outlined
          v-if="
            formSubmitted && $v.appointmentDate && !$v.appointmentDate.required
          "
          >La date est requise !</v-alert
        >
        <v-sheet tile height="54" color="grey lighten-3" class="d-flex">
          <v-btn
            icon
            class="ma-2"
            :disabled="previousMonthDisabled"
            @click="previousMonth"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-toolbar-title v-if="!$refs.calendar">
            {{ calendarTitle }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="nextMonth">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="400">
          <v-calendar
            ref="calendar"
            v-model="calendarDate"
            :weekdays="weekday"
            :type="type"
            :now="today"
            :start="startDate"
            :locale="locale"
            color="primary"
          >
            <template #day-label="{ date, day }">
              <v-btn
                @click="dateSelected(date)"
                fab
                small
                elevation="null"
                class="transparent"
                :class="
                  date === appointmentDate.toISOString().split('T')[0]
                    ? 'secondary'
                    : date === today
                    ? 'primary'
                    : ''
                "
                :disabled="date < today"
              >
                <span v-text="day" />
                <span
                  v-text="formatDate(new Date(date), { month: 'short' })"
                  v-if="day == 1"
                />
              </v-btn>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" lg="3">
        <p class="text-h5">Choix de l'heure</p>
        <v-alert
          type="error"
          outlined
          v-if="
            formSubmitted && $v.appointmentSlot && !$v.appointmentSlot.required
          "
          >L'heure est requise !</v-alert
        >
        <v-card class="mx-auto" tile>
          <v-list>
            <v-subheader>
              {{ slotListSubheader }}
            </v-subheader>
            <v-list-item-group
              v-model="appointmentSlot"
              @change="appointmentSlotChanged"
              color="primary"
              v-if="appointmentDate"
            >
              <v-list-item v-for="(slot, i) in slots" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="'mdi-clock'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="slotToText(slot)"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <div class="d-flex justify-center">
          <v-btn class="button-shadow" color="primary" @click="prev">
            Précédent
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="button-shadow" color="primary" @click="next">
            Suivant
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { formatDate, formatTime } from "@/utils/date";

function getNearestDate(date) {
  const nearestDate = date;

  if (nearestDate.getDay() == 6) {
    nearestDate.setDate(nearestDate.getDate() + 2);
  } else if ([0].includes(nearestDate.getDay())) {
    nearestDate.setDate(nearestDate.getDate() + 1);
  }

  return nearestDate;
}

const firstAvailableDate = getNearestDate(new Date());

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      displayError: false,

      appointmentDate: firstAvailableDate,
      appointmentSlot: null,
      formSubmitted: false,

      today: new Date().toISOString().split("T")[0],
      startDate: firstAvailableDate.toISOString().split("T")[0],
      calendarDate: new Date(),
      type: "month",
      weekday: [1, 2, 3, 4, 5],
      locale: "fr",
      events: [],
    };
  },

  computed: {
    ...mapGetters("appointmentForm", {
      currentStep: "step",
    }),

    ...mapGetters("slot", ["slots", "isLoading"]),

    calendarTitle() {
      return formatDate(firstAvailableDate, { year: "numeric", month: "long" });
    },

    slotListSubheader() {
      if (!this.appointmentDate) {
        return "Aucune date n'a été sélectionnée";
      } else {
        const formattedDate = formatDate(this.appointmentDate);

        if (this.isLoading) {
          return `Recherche des plages disponibles pour le ${formattedDate}`;
        } else if (this.slots.length > 0) {
          return `Plages disponibles pour le ${formattedDate}`;
        } else {
          return `Aucune plage disponible pour le ${formattedDate}`;
        }
      }
    },

    slotToText() {
      return (slot) => {
        const startTime = formatTime(slot.start);

        const endTime = formatTime(slot.end);

        return `${startTime} - ${endTime}`;
      };
    },

    previousMonthDisabled() {
      const momentCalendarDate = moment(this.calendarDate);
      const momentFirstAvailableDate = moment(firstAvailableDate);

      return (
        momentCalendarDate.format("YYYYMM") <=
        momentFirstAvailableDate.format("YYYYMM")
      );
    },

    rules() {
      return {
        appointmentDate: { required },
        appointmentSlot: { required },
      };
    },
  },

  methods: {
    ...mapActions("appointmentForm", ["incrementStep", "decrementStep"]),

    ...mapActions("slot", {
      loadTimeSlots: "loadSlots",
    }),

    ...mapActions("appointment", ["updateObjAppointment"]),

    formatDate,
    formatTime,

    prev() {
      this.decrementStep();
    },

    next() {
      this.formSubmitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjAppointment({
        slot: {
          start: this.slots[this.appointmentSlot].start,
          end: this.slots[this.appointmentSlot].end,
        },
      });

      this.incrementStep();
    },

    previousMonth() {
      this.$refs.calendar.prev();
    },

    nextMonth() {
      this.$refs.calendar.next();
    },

    dateSelected(date) {
      this.displayError = false;

      this.appointmentDate = new Date(date);
      this.appointmentSlot = null;
      this.$v.appointmentDate.$touch();

      this.loadTimeSlots(this.appointmentDate);
    },

    appointmentSlotChanged() {
      this.$v.appointmentSlot.$touch();
    },
  },

  created() {
    this.dateSelected(firstAvailableDate);
  },
};
</script>
