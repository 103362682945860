<template>
  <v-card class="mx-auto mt-16">
    <div class="float-left">
      <v-list-item>
        <router-link :to="home.to" custom v-slot="{ href, navigate }">
          <v-btn class="text-none" depressed :href="href" @click="navigate">
            <v-list-item-icon
              color="grey"
              class="v-icon notranslate mx-3 fa fa-home theme--light"
            ></v-list-item-icon>
            <v-list-item-content class="hidden-sm-and-down">
              <v-list-item-title>{{ home.name }} </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </router-link>
      </v-list-item>
    </div>
    <div class="float-right">
      <v-list-item v-if="isAuth">
        <v-menu bottom :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="text-none" depressed v-bind="attrs" v-on="on">
              <v-list-item-icon
                color="grey"
                class="v-icon notranslate mx-3 fa fa-user theme--light"
              ></v-list-item-icon>
              <v-list-item-content class="hidden-sm-and-down">
                <v-list-item-title>{{ fullName }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon
                color="grey"
                class="v-icon notranslate mx-3 fa fa-caret-down theme--light"
              ></v-list-item-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-btn class="text-none" depressed @click="signout" block>
                <v-list-item-icon
                  color="grey"
                  class="v-icon notranslate mx-3 fa fa-sign-out theme--light"
                ></v-list-item-icon>
                <v-list-item-title>Se déconnecter</v-list-item-title>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    home: { type: Object, required: true },
  },

  computed: {
    ...mapGetters("user", ["isAuthenticated", "user"]),

    fullName() {
      return this.user.firstName + " " + this.user.lastName;
    },

    isAuth() {
      return this.isAuthenticated;
    },
  },

  methods: {
    ...mapActions("user", ["logout"]),

    signout() {
      this.logout();
    },
  },
};
</script>
