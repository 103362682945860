<template>
  <v-container fluid class="mt-n12">
    <v-overlay :value="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="ma-0">
      <v-col cols="2" class="offset-2">
        <appClientDetail :client="client" v-if="!emptyClient"></appClientDetail>
        <appAdminNavigation></appAdminNavigation>
      </v-col>
      <v-col cols="6">
        <v-card width="100%" class="ma-1">
          <v-card-title>
            <div class="d-flex justify-center">Liste des PVs disponibles</div>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
          </v-card-title>
        </v-card>

        <v-alert
          type="success"
          dismissible
          class="ma-1"
          v-if="infoMessage !== null"
        >
          {{ infoMessage }}
        </v-alert>

        <v-card width="100%" class="ma-1">
          <v-container fluid>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="reports"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-sort="customSort"
                class="elevation-1"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template slot="item.dateTime" slot-scope="{ item }">
                  {{ formatDateTime(item.dateTime) }}
                </template>
                <template slot="item.actions" slot-scope="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="viewItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!isViewable(item, user)"
                        >mdi-eye
                      </v-icon>
                    </template>
                    <span>Voir le PV</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!isEditable(item, user)"
                        >mdi-file-edit
                      </v-icon>
                    </template>
                    <span>Editer le PV</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-row>
                  <v-col>
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :total-visible="7"
                    ></v-pagination>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col
                        ><v-select
                          v-model="itemsPerPage"
                          label="Nombre de rendez-vous par page"
                          :items="[1, 5, 10, 15]"
                          @input="itemsPerPage = parseInt($event, 10)"
                        ></v-select
                      ></v-col>
                      <v-col>
                        <v-text-field
                          :value="totalReports"
                          label="Nombre total de rendez-vous"
                          disabled
                          class="mb-4 px-5"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isObjectEmpty } from "@/utils/object";
import adminNavigation from "@/components/Admin/Navigation";
import clientDetail from "@/components/Admin/ClientDetail";
import { formatDateTime } from "@/utils/date";
import { isViewable, isEditable } from "@/utils/report";

export default {
  data: () => ({
    headers: [
      { text: "Date et heure du rendez-vous", value: "dateTime" },
      { text: "Nom du psychologue", value: "socCollaboratorLastName" },
      { text: "Prénom du psychologue", value: "socCollaboratorFirstName" },
      { text: "Type de PV", value: "typeOfReport" },
      { text: "Action", value: "actions", sortable: false },
    ],

    clientEmail: null,

    pageCount: 0,
    queryObj: {},

    infoMessage: null,
  }),

  computed: {
    ...mapGetters("user", ["user"]),

    ...mapGetters("layout", ["message"]),

    ...mapGetters("dataTable", {
      dataTableParams: "params",
      dataTableParamsForQueryString: "paramsForQueryString",
    }),

    ...mapGetters("adminReportList", [
      "reports",
      "totalReports",
      "client",
      "isLoading",
    ]),

    emptyClient() {
      return isObjectEmpty(this.client);
    },

    page: {
      get: function () {
        return this.dataTableParams.page;
      },
      set: function (newValue) {
        this.updateParams({ page: newValue });
      },
    },

    itemsPerPage: {
      get: function () {
        return this.dataTableParams.itemsPerPage;
      },
      set: function (newValue) {
        this.updateParams({ itemsPerPage: newValue });
      },
    },

    sortBy: {
      get: function () {
        return this.dataTableParams.sortBy;
      },
      set: function (newValue) {
        this.updateParams({ sortBy: newValue });
      },
    },

    sortDesc: {
      get: function () {
        return this.dataTableParams.sortDesc;
      },
      set: function (newValue) {
        this.updateParams({ sortDesc: newValue });
      },
    },
  },

  watch: {
    itemsPerPage() {
      this.updateParams({ itemsPerPage: this.itemsPerPage });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    page() {
      this.updateParams({ page: this.page });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    sortBy() {
      this.updateParams({ sortBy: this.sortBy });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    sortDesc() {
      this.updateParams({ sortDesc: this.sortDesc });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },
  },

  methods: {
    ...mapActions("layout", ["clearMessage"]),

    ...mapActions("commonReport", ["isViewable", "isEditable"]),

    ...mapActions("dataTable", ["resetConfig", "updateParams"]),

    ...mapActions("adminReportList", ["loadReports"]),

    formatDateTime,

    isViewable,
    isEditable,

    back() {
      this.$router.go(-1);
    },

    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "dateTime") {
          if (!isDesc[0]) {
            return a[index] - b[index];
          } else {
            return b[index] - a[index];
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },

    viewItem(item) {
      if (item.typeOfReport === "Psychologique") {
        this.$router.push({
          name: "psychological-appointment-view-report",
          params: { id: item.id },
          query: { origin: "admin" },
        });
      } else {
        this.$router.push({
          name: "permanency-appointment-view-report",
          params: { id: item.id },
          query: { origin: "admin" },
        });
      }
    },

    editItem(item) {
      if (item.typeOfReport === "Psychologique") {
        this.$router.push({
          name: "psychological-appointment-edit-report",
          params: { id: item.id },
          query: { origin: "admin" },
        });
      } else {
        this.$router.push({
          name: "permanency-appointment-edit-report",
          params: { id: item.id },
          query: { origin: "admin" },
        });
      }
    },
  },

  async created() {
    this.clientEmail = this.$route.params.email;

    if (this.message !== null) {
      this.infoMessage = this.message;
      this.clearMessage();
    }

    await this.loadReports({ email: this.clientEmail });

    this.resetConfig({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["dateTime"],
      sortDesc: [true],
    });

    this.updateParams(this.$route.query);

    this.queryObj = Object.assign(this.dataTableParams);
    this.$router.replace({ query: this.queryObj }).catch(() => {});
  },

  components: {
    appClientDetail: clientDetail,
    appAdminNavigation: adminNavigation,
  },
};
</script>
