<template>
  <v-container fluid class="mt-n12">
    <v-overlay :value="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="ma-0">
      <v-col cols="2" class="offset-2">
        <appPsychologistDetail></appPsychologistDetail>
        <appAdminNavigation></appAdminNavigation>
      </v-col>
      <v-col cols="6">
        <v-card width="100%" class="ma-1">
          <v-card-title>
            <div class="d-flex justify-center">Liste des clients</div>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
          </v-card-title>
        </v-card>

        <v-form ref="form">
          <v-card width="100%" class="ma-1">
            <v-card-text>
              <form>
                <v-text-field
                  v-model="searchModel"
                  append-icon="mdi-magnify"
                  label="Client, psychologue, e-mail"
                  single-line
                  hide-details
                ></v-text-field>
              </form>
            </v-card-text>
          </v-card>
        </v-form>

        <v-card width="100%" class="ma-1">
          <v-container fluid>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="clients"
                :search="searchModel"

                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="elevation-1"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template slot="item.dateTime" slot-scope="{ item }">
                  {{ formatDateTime(createDateTimeTz(item.dateTime)) }}
                </template>

                <template slot="item.actions" slot-scope="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="appointmentListForItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-view-list
                      </v-icon>
                    </template>
                    <span>Liste des PVs</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-row>
                  <v-col>
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :total-visible="7"
                    ></v-pagination>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col
                        ><v-select
                          v-model="itemsPerPage"
                          label="Nombre de rendez-vous par page"
                          :items="[1, 5, 10, 15]"
                          @input="itemsPerPage = parseInt($event, 10)"
                        ></v-select
                      ></v-col>
                      <v-col>
                        <v-text-field
                          :value="totalClients"
                          label="Nombre total de rendez-vous"
                          disabled
                          class="mb-4 px-5"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import psychologistDetail from "@/components/Admin/PsychologistDetail";
import adminNavigation from "@/components/Admin/Navigation";
import { createDateTimeTz, formatDateTime } from "@/utils/date";

export default {
  data: () => ({
    headers: [],

    pageCount: 0,

    payload: {},
    queryObj: {},

    searchModel: '',
  }),

  computed: {
    ...mapGetters("dataTable", {
      dataTableParams: "params",
      dataTableParamsForQueryString: "paramsForQueryString",
    }),

    ...mapGetters("adminMyClients", ["clients", "totalClients", "isLoading"]),

    ...mapGetters("user", {
      connectedUser: "user",
      connectedUserHasRole: "hasRole",
    }),

    page: {
      get: function () {
        return this.dataTableParams.page;
      },
      set: function (newValue) {
        this.updateParams({ page: newValue });
      },
    },

    itemsPerPage: {
      get: function () {
        return this.dataTableParams.itemsPerPage;
      },
      set: function (newValue) {
        this.updateParams({ itemsPerPage: newValue });
      },
    },

    sortBy: {
      get: function () {
        return this.dataTableParams.sortBy;
      },
      set: function (newValue) {
        this.updateParams({ sortBy: newValue });
      },
    },

    sortDesc: {
      get: function () {
        return this.dataTableParams.sortDesc;
      },
      set: function (newValue) {
        this.updateParams({ sortDesc: newValue });
      },
    },
  },

  watch: {
    async searchModel() {
      this.updateQueryObj({search: this.searchModel});
      this.updateRoute();
    },

    async itemsPerPage() {
      this.updateParams({ itemsPerPage: this.itemsPerPage });
      this.updateQueryObj({search: this.searchModel});
      this.updateRoute();
    },

    async page() {
      this.updateParams({ page: this.page });
      this.updateQueryObj({search: this.searchModel});
      this.updateRoute();
    },

    async sortBy() {
      this.updateParams({ sortBy: this.sortBy });
      this.updateQueryObj({search: this.searchModel});
      this.updateRoute();
    },

    async sortDesc() {
      this.updateParams({ sortDesc: this.sortDesc });
      this.updateQueryObj({search: this.searchModel});
      this.updateRoute();
    },
  },

  methods: {
    ...mapActions("dataTable", [
      "resetConfig",
      "resetSortConfig",
      "updateParams",
    ]),

    ...mapActions("adminMyClients", ["loadClients"]),

    createDateTimeTz,
    formatDateTime,

    back() {
      this.$router.go(-1);
    },

    updatePayload() {
      this.payload = {};

      if (!this.connectedUserHasRole(["soc-admin"])) {
        this.payload.socCollaboratorId = this.connectedUser.pernum;
      }
    },

    updateQueryObj(data) {
      this.queryObj = {};

      if ("search" in data) {
        this.queryObj.search = data.search;
      }

      this.queryObj = Object.assign(
        this.queryObj,
        this.dataTableParamsForQueryString
      );
    },

    updateSearchModel(data) {
      if ("search" in data) {
        this.searchModel = data.search;
      }
    },

    updateRoute() {
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    appointmentListForItem(item) {
      this.$router.push({
        name: "admin-report-list",
        params: { email: item.email },
      });
    },
  },

  async created() {
    this.resetConfig({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["dateTime"],
      sortDesc: [true],
    });

    this.updateParams(this.$route.query);
    this.updatePayload(this.$route.query);
    this.updateSearchModel(this.$route.query);

    await this.loadClients(this.payload);

    if (this.connectedUserHasRole(["soc-admin"])) {
      this.headers = [
        {text: "Client", value: "clientFullName"},
        {text: "Dernier rendez-vous", value: "dateTime"},
        {text: "Téléphone", value: "phone", sortable: false},
        {text: "Psychologue", value: "collaboratorFullName"},
        {text: "Action", value: "actions", sortable: false},
      ];
    } else {
      this.headers = [
        {text: "Client", value: "clientFullName"},
        {text: "Dernier rendez-vous", value: "dateTime"},
        {text: "Téléphone", value: "phone", sortable: false},
        {text: "E-mail", value: "email"},
        {text: "Action", value: "actions", sortable: false},
      ];
    }
  },

  components: {
    appPsychologistDetail: psychologistDetail,
    appAdminNavigation: adminNavigation,
  },
};
</script>
